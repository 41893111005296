import { createRoot } from "react-dom/client";
import "./index.scss";
import { MantineProvider } from "@mantine/core";
import MainRouter from "./app/MainRouter";

createRoot(document.getElementById("root")!).render(
	<MantineProvider>
		<MainRouter />
	</MantineProvider>,
);
