import styles from "./spinner.module.scss";

export const Spinner = () => {
	console.log("Render Spinner...");
	return (
		<div className={styles.loaderWrapper}>
			<div className={styles.spinner}></div>
		</div>
	);
};

export default Spinner;
