import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { AppRoutes } from "./AppRoutes";
import { ErrorBoundary } from "../shared/ui/errorBoundary";
import Spinner from "../shared/ui/errorBoundary/spinner/Spinner";

export default function MainRouter() {
	console.log("Render MainRouter...");

	return (
		<BrowserRouter>
			<ErrorBoundary>
				<Suspense fallback={<Spinner />}>
					<MantineProvider defaultColorScheme="dark">
						<AppRoutes />
					</MantineProvider>
				</Suspense>
			</ErrorBoundary>
		</BrowserRouter>
	);
}
