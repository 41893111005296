import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export const basePath = import.meta.env.BASE_URL;

const LayoutLazy = lazy(() => import("../widgets/Layout/ui/Layout"));
const WelcomePageLazy = lazy(() => import("../pages/WelcomePage/ui/WelcomePage"));

export const AppRoutes = () => {
	console.log("Render AppRoutes...");

	return (
		<Routes>
			<Route path={basePath} element={<LayoutLazy />}>
				<Route path={basePath} element={<WelcomePageLazy />} />
			</Route>
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	);
};
